<section>
    <mat-dialog-content>
        <div>
            <div style="text-align: end;">
                <span class="material-icons iconClose" mat-dialog-close>close</span>
            </div>
            <div style="text-align:center">
                <p class="dialog-message">{{message}}</p>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center">
        <button mat-raised-button color="primary" id="confirmBtn" (click)="onConfirmClick()" tabindex="1"
            *ngIf="confirmButtonText">{{confirmButtonText}}</button>
        <button mat-raised-button id="cancelBtn" mat-dialog-close tabindex="-1"
            *ngIf="cancelButtonText">{{cancelButtonText}}</button>
    </mat-dialog-actions>
</section>