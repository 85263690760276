import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "app-mat-dialog",
  templateUrl: "./mat-dialog.component.html",
  styleUrls: ["./mat-dialog.component.scss"]
})

export class MatDialogComponent implements OnInit {

  message = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'No';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MatDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok;
        this.cancelButtonText = data.buttonText.cancel;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit() {

  }
}
