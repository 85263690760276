<header class="header-section">

  <!-- Navigation -->
  <nav class="navbar navbar-expand-lg navbar-light header-bg top-bar mb-4 static-top shadow">

    <!-- Branding Logo -->

    <a class="navbar-brand ml-5" *ngIf="role==='ADMIN'" href="#"><img
        src="assets/img/Harris-Poll-Logo-Lockup-Descriptor-primary-black1.png" width="220" /></a>
    <a class="navbar-brand ml-5" *ngIf="role==='CLIENT'" href="#"><img [src]="companyLogo" /></a>
    <a class="navbar-brand ml-5" *ngIf="role==='OPS'" href="#"><img
        src="assets/img/Harris-Poll-Logo-Lockup-Descriptor-primary-black1.png" width="220" /></a>

    <!-- Toggler Button -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse pull-right" id="navbarResponsive">
      <ul class="navbar-nav list-nav ml-auto user-dropdown">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="profile-username ml-3">{{userName}}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" (click)="logOut()"><i
                class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>

</header>