import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})

export class HeaderComponent implements OnInit {

  public userName: string;
  public companyLogo;
  public role;
  public httpOptions;

  constructor(private router: Router,
    private sanitizer: DomSanitizer,
    private apiService: ApiService) { }

  ngOnInit() {
    this.userName = localStorage.getItem('userName');
    let str = localStorage.getItem('companyLogo');
    this.role = localStorage.getItem('role')

    this.companyLogo = this.sanitizer.bypassSecurityTrustUrl('data:image/svg+xml;base64,' + str);
  }



  logOut() {
    localStorage.setItem('token', '');
    this.router.navigateByUrl('/login');
    localStorage.clear()

    // this.httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json; charset=utf-8',
    //     'Access-Control-Allow-Origin': '*',
    //     'Authorization': 'Bearer ' + localStorage.getItem('token')
    //   })
    // };
    // this.apiService.getLogOut().subscribe((logoutRes) => {
    //   console.log(logoutRes);
    // });
  }
}
