import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { CommonMaterialModule } from '../common-material-modules/common-material.module';

@NgModule({
    declarations: [HeaderComponent, FooterComponent],
    imports: [
        CommonMaterialModule,
        CommonModule
    ],
    exports: [HeaderComponent, FooterComponent]
})

export class HomeModule {
    constructor() { }
}