import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    // public variables
    public messageSource = new BehaviorSubject(null);
    public currentMessage = this.messageSource.asObservable();
    public storageData = 'TDHLocal';
    // private base_url: string = "server";
    private base_url: string = environment.URL;
    public loginResponseError;

    public httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Access-Control-Allow-Origin': '*'
        })
    };

    // URL
    loginURL: string = this.base_url + "/authenticate";
    companyURL: string = this.base_url + "/getAllCompanies";
    waveURL: string = this.base_url + "/getAllWaves";
    rowCountURL: string = this.base_url + "/getRowCount?waveId=";
    uploadURL: string = this.base_url + "/fileUpload";
    downloadURL: string = this.base_url + "/fileDownload/";
    masterDownloadURL: string = this.base_url + "/fileDownload/masterFile/";

    constructor(private _http: HttpClient) { }

    getLoginDetails(obj): any {
        return this._http.post(this.loginURL, obj, this.httpOptions);
    }

    getCompanyDetails(httpOps): any {
        return this._http.get(this.companyURL, httpOps);
    }

    getWaveDetails(httpOps): any {
        return this._http.get(this.waveURL, httpOps);
    }

    getRowCount(rowObj, httpOps): any {
        return this._http.get(this.rowCountURL + rowObj.companyId + "&companyId=" + rowObj.waveId, httpOps);
    }

    getUploadDetails(uploadObj, header): any {
        return this._http.post(this.uploadURL, uploadObj, header);
    }

    getDownloadDetails(companyId, waveId): any {

        return this._http.get(this.downloadURL + companyId + "/" + waveId, {
            headers: new HttpHeaders({
                'Content-Type': 'application/excel',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            }), observe: 'response', responseType: 'blob'
        }).pipe(tap(
            // Log the result or error
            response => {
                this.downloadFile(response.body, this.parseFilename(response.headers.get('Content-Disposition')), '');
            },
            error => 'Get File Error' + console.error(error)
        ));
    }

    getMasterDownloadDetails(waveId): any {

        return this._http.get(this.masterDownloadURL + waveId, {
            headers: new HttpHeaders({
                'Content-Type': 'application/excel',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            }), observe: 'response', responseType: 'blob'
        }).pipe(tap(
            // Log the result or error
            response => {
                this.downloadFile(response.body, this.parseFilename(response.headers.get('Content-Disposition')), '');
            },
            error => 'Get File Error' + console.error(error)
        ));
    }



    private downloadFile(data: Blob, filename: string, type?) {
        let blob;
        if (type === 'pdf') {
            blob = new Blob([data], { type: 'application/pdf' });
        } else if (type === 'jpg') {
            blob = new Blob([data], { type: 'image/jpeg' });
        } else {
            blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        }

        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    private parseFilename(fileName) {
        if (!fileName) { return null; }
        const matches = fileName.split('=');
        return matches && matches.length > 1 ? matches[1] : null;
    }
}
